<template>
  <gov-width-container>
    <vue-headful :title="`${appName} - Page Not Found`" />

    <gov-main-wrapper>
      <gov-grid-row>
        <gov-grid-column width="two-thirds">
          <gov-heading size="xl">Page not found</gov-heading>

          <gov-body
            >If you typed the web address, check it is correct.</gov-body
          >

          <gov-body
            >If you pasted the web address, check you copied the entire
            address.</gov-body
          >
        </gov-grid-column>
      </gov-grid-row>
    </gov-main-wrapper>
  </gov-width-container>
</template>

<script>
export default {
  name: "NotFound"
};
</script>
